import { SafeHtml } from "@angular/platform-browser";
import { Source, GenAIResponse } from "../../interface/partials/gen-ai-response";

export class GenAIResponseModel implements GenAIResponse {
    public output_text: string;
    public is_answer_unknown: boolean;
    public intermediate_steps: any;
    public errorCode: string;
    public status: string;
    public errorMessage: string;
    public references: Source[];
    public doc_meta_info: any;
    public query: string;
    // public html?: SafeHtml;
    // public thumbsUp?: boolean=false;
    // public thumbsDown?: boolean=false;

    constructor(args : Partial<GenAIResponseModel>) {
      Object.assign(this, args);
    }
}