import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OuxAuthenticationService, OuxConfigService, OuxExceptionsHandleError, OuxExceptionsHandlerService } from '@cisco/oux-common';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take } from 'rxjs/operators';
import { UserDetailsStore } from '../stores/user-details.store';
import { MetadataStore } from '../stores/metadata.store';
import { Source, GenAIResponse } from '../models/interface/partials/gen-ai-response';
import { ChatbotStore } from '../stores/chatbot.store';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import showdown from 'showdown';
import { PageTitle } from '../models/interface/partials/page-titles';
import { Conversation } from '../models/interface/partials/chatbot-conversation';
import { ChatbotTitleResponse } from '../models/interface/response/chatbot-title-response';
import { ChatbotStoredDataRequest } from '../models/interface/request/chatbot-stored-data-request';
import { ConversationHistory } from '../models/interface/partials/conversation-history';
import { Message } from '../models/interface/partials/chatbot-message';
import { ChatbotStoredDataResponse } from '../models/interface/response/chatbot-stored-data';
import { ChatbotSuggestionsResponse } from '../models/interface/response/chatbot-suggestions-response';
import { Suggestions } from '../models/interface/partials/suggestions';
import { SuggestionsModel } from '../models/concrete/partials/suggestions.model';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  private baseUri: string;
  private GenAIResponseUri: string;
  private chatbotCrudOperationUri: string;
  private chatbotTitleGenerateUri: string;
  private chatbotUniqueIdGenerateUri: string;
  private chatbotSuggestionsUri: string;

  private converter = new showdown.Converter({ simpleLineBreaks: true });

  /**
 * Create service mapping for http exception handling
 */
  private ouxHandleError: OuxExceptionsHandleError =
    this.ouxExceptionsSvc.createHandleError("OrderService");

  constructor(
    private http: HttpClient,
    private ouxAuthSvc: OuxAuthenticationService,
    private ouxConfigSvc: OuxConfigService,
    private ouxExceptionsSvc: OuxExceptionsHandlerService,
    private userDetailsStore: UserDetailsStore,
    private metadataStore: MetadataStore,
    private chatbotStore: ChatbotStore,
    private sanitizer: DomSanitizer) {
    let apiUri = this.ouxConfigSvc.getAppConfigValue("apiUri");

    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue("gatewayUri")}${this.ouxConfigSvc.getAppConfigValue("organizationUri")}${this.ouxConfigSvc.getAppConfigValue("apiVersion")}`;
    this.GenAIResponseUri = apiUri.chatbot4Response;
    this.chatbotCrudOperationUri = apiUri.chatbotCrudOperation;
    this.chatbotTitleGenerateUri = apiUri.chatbotTitleGenerate;
    this.chatbotUniqueIdGenerateUri = apiUri.chatbotUniqueIdGenerate;
    this.chatbotSuggestionsUri = apiUri.chatbotSuggestions;
  }

  /**
* Stages our Http Request Headers
*/
  private getOptions(): { headers: HttpHeaders } {
    const OPTIONS: { headers: HttpHeaders } = {
      headers: new HttpHeaders()
        .set("Authorization", this.ouxAuthSvc.getAuthToken())
        .append("Content-Type", "application/json"),
    };

    return OPTIONS;
  }

  //Formatting string to escape special characters for Chatbot API
  public escapeQueryString(query: string): string {
    return query.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
  }

  /**
   * Function To fetch Response from GenAI API
   */
  public fetchGenAIResponse(message: Message): Observable<Message> {
    this.chatbotStore.setLoadingState(true);
    const URL = `${this.baseUri}${this.GenAIResponseUri}`;
    const OPTIONS = this.getOptions();

    let request = {
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      query: this.escapeQueryString(message?.content?.query),
    }

    const REQUEST$ = this.http.post<GenAIResponse>(URL, request, OPTIONS).pipe(
      switchMap((response) => {
        if (!response) {
          return throwError(response);
        }
        // if(response.is_answer_unknown == true){
        //   response.output_text = "Sorry, VizBot is unable to provide an answer to your query at this time.";
        // }
        message.content = response;
        message.html = this.convertMarkdownToHtml(response);
        message.content.references = this.removeDuplicateFilenames(response.references);



        //Update to the store
        this.chatbotStore.updateMessage(message);

        return of(message);
      }),
      finalize(() => {
        this.chatbotStore.setLoadingState(false);
        this.addBotMessageToHistory(message).pipe(take(1)).subscribe();

      }),
      catchError((error) => {
        // create operation mapping for http exception handling
        return this.ouxHandleError("fetchGenAIResponse", error)(error);
      })
    );

    return REQUEST$;
  }


  /**
   * Function add AI response to history
   */
  public addBotMessageToHistory(message: Message): Observable<any> {
    // this.chatbotStore.setLoadingState(true);
    const URL = `${this.baseUri}${this.chatbotCrudOperationUri}`;
    const OPTIONS = this.getOptions();
    var pageId;
    this.chatbotStore.currentPageId$.pipe(take(1)).subscribe((currentPageId: number) => {
      pageId = currentPageId;
      // Use the value here
    });

    let request: ChatbotStoredDataRequest = {
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      action: "ADD",
      feedbackRating: 0,
      query: message?.content?.query,
      response: message?.content?.output_text,
      references: JSON.stringify(message?.content?.references),
      interactionId: message.interactionId,
      pageId: pageId,
      isAnswerUnknown: message?.content?.is_answer_unknown == true ? 'Y' : 'N',
      source: "Visibility"
    }

    const REQUEST$ = this.http.post<ChatbotStoredDataResponse>(URL, request, OPTIONS).pipe(
      switchMap((response) => {
        if (!response) {
          return throwError(response);
        }
        this.chatbotStore.updatePageTitles(response?.data?.pageTitles[0]);
        return of(response?.data?.conversaton[0]);
      }),
      finalize(() => {

        // this.chatbotStore.setLoadingState(false);
      }),
      catchError((error) => {
        // create operation mapping for http exception handling
        return this.ouxHandleError("fetchGenAIResponse", error)(error);
      })
    );

    return REQUEST$;
  }


  /**
   * Function To update feedback rating
   */
  public updateFeedback(message: Message): Observable<any> {
    // this.chatbotStore.setLoadingState(true);
    const URL = `${this.baseUri}${this.chatbotCrudOperationUri}`;
    const OPTIONS = this.getOptions();
    var pageId;
    this.chatbotStore.currentPageId$.pipe(take(1)).subscribe((currentPageId: number) => {
      pageId = currentPageId;
      // Use the value here
    });

    let request: ChatbotStoredDataRequest = {
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      action: "UPDATE",
      feedbackRating: message.thumbsDown ? -1 : message.thumbsUp ? 1 : 0,
      query: message?.content?.query,
      response: message?.content?.output_text,
      references: JSON.stringify(message?.content?.references),
      interactionId: message.interactionId,
      pageId: pageId,
      source: "Visibility"
    }

    const REQUEST$ = this.http.post<ChatbotStoredDataResponse>(URL, request, OPTIONS).pipe(
      switchMap((response) => {
        if (!response) {
          return throwError(response);
        }

        return of(response?.data?.conversaton[0]);
      }),
      finalize(() => {
        // this.chatbotStore.setLoadingState(false);
      }),
      catchError((error) => {
        // create operation mapping for http exception handling
        return this.ouxHandleError("fetchGenAIResponse", error)(error);
      })
    );

    return REQUEST$;
  }

  /**
   * Function To generate title from GenAI and add to history
   */
  async generateTitleAndAddToHistory(message: Message) {
    const title = await this.generatePageTitle(message).toPromise();
    this.updateTitleToHistory(title, message).pipe(take(1)).subscribe();
    this.chatbotStore.updatePageTitles({ pageId: message.pageId, page_header: title, interaction_id: message.interactionId, creationDate: new Date() });
  }

  /**
   * Function To update title in history
   */
  public updateTitleToHistory(pageTitle: string, message: Message): Observable<ConversationHistory> {
    // this.chatbotStore.setLoadingState(true);
    const URL = `${this.baseUri}${this.chatbotCrudOperationUri}`;
    const OPTIONS = this.getOptions();
    let request: ChatbotStoredDataRequest = {
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      action: "UPDATE",
      pageId: message?.pageId,
      source: "Visibility"

    }
    if (pageTitle) {
      request.pageTitle = pageTitle;
    }
    else if (pageTitle?.length == 0) {
      request.pageTitle = "Generic Request";
    }

    const REQUEST$ = this.http.post<ChatbotStoredDataResponse>(URL, request, OPTIONS).pipe(
      switchMap((response) => {
        if (!response) {
          return throwError(response);
        }
        return of(response?.data?.conversaton[0]);
      }),
      finalize(() => {
        // this.chatbotStore.setLoadingState(false);
        // this.getAllPageTitles().pipe(take(1)).subscribe();
      }),
      catchError((error) => {
        // create operation mapping for http exception handling
        return this.ouxHandleError("fetchGenAIResponse", error)(error);
      })
    );

    return REQUEST$;
  }

  /**
   * Function To retreive page titles from database
   */
  public getAllPageTitles(): Observable<PageTitle[]> {
    // this.chatbotStore.setLoadingState(true);
    const URL = `${this.baseUri}${this.chatbotCrudOperationUri}`;
    const OPTIONS = this.getOptions();

    let request: ChatbotStoredDataRequest = {
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      action: "GET_PH",
      source: "Visibility"

    }

    const REQUEST$ = this.http.post<ChatbotStoredDataResponse>(URL, request, OPTIONS).pipe(
      switchMap((response) => {
        if (!response) {
          return throwError(response);
        }
        this.chatbotStore.setPageTitles(response?.data?.pageTitles);

        return of(response?.data?.pageTitles);
      }),
      finalize(() => {
        // this.chatbotStore.setLoadingState(false);
      }),
      catchError((error) => {
        // create operation mapping for http exception handling
        return this.ouxHandleError("fetchGenAIResponse", error)(error);
      })
    );

    return REQUEST$;
  }


  /**
   * Function To retreive quick pick suggestions from database
   */
  public getSuggestions(): Observable<Suggestions> {

    const url = `${this.baseUri}${this.chatbotSuggestionsUri}`;
    const options = this.getOptions();

    let request$ = this.http.get<ChatbotSuggestionsResponse>(url, options)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          let typed = new SuggestionsModel(response?.data)
          console.log(typed);
          return of(typed);
        }),
        catchError(error => {
          // create operation mapping for http exception handling 
          return this.ouxHandleError('fetchGenAISuggestions', error)(error);
        })
      );
    console.log(request$);
    return request$;
  }

  /**
   * Function To delete conversation from history
   */
  public deleteConversationFromHistory(pageId: number): Observable<PageTitle[]> {
    // this.chatbotStore.setLoadingState(true);
    const URL = `${this.baseUri}${this.chatbotCrudOperationUri}`;
    const OPTIONS = this.getOptions();

    let request: ChatbotStoredDataRequest = {
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      action: "DELETE",
      source: "Visibility",
      pageId: pageId

    }

    const REQUEST$ = this.http.post<ChatbotStoredDataResponse>(URL, request, OPTIONS).pipe(
      switchMap((response) => {
        if (!response) {
          return throwError(response);
        }

        return of(response?.data?.pageTitles);
      }),
      finalize(() => {
        this.chatbotStore.deleteConversation(pageId);
        // this.chatbotStore.setLoadingState(false);
      }),
      catchError((error) => {
        // create operation mapping for http exception handling
        return this.ouxHandleError("fetchGenAIResponse", error)(error);
      })
    );

    return REQUEST$;
  }

  /**
   * Function To retreive conversation from history
   */
  public getHistoricalConversation(pageId: number): Observable<Conversation[]> {
    // this.chatbotStore.setLoadingState(true);
    const URL = `${this.baseUri}${this.chatbotCrudOperationUri}`;
    const OPTIONS = this.getOptions();
    let request = {
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      pageId: pageId,
      action: 'GET_CONV',
      source: 'Visibility'
    }

    const REQUEST$ = this.http.post<ChatbotStoredDataResponse>(URL, request, OPTIONS).pipe(
      switchMap((response) => {
        if (!response) {
          return throwError(response);
        }

        this.chatbotStore.clearConversationData();
        this.chatbotStore.setPageId(pageId);
        this.chatbotStore.setConversationFromHistory(pageId, response?.data?.conversaton);

        return of(response?.data?.conversaton);
      }),
      finalize(() => {
        // this.chatbotStore.setLoadingState(false);
      }),
      catchError((error) => {
        // create operation mapping for http exception handling
        return this.ouxHandleError("fetchGenAIResponse", error)(error);
      })
    );

    return REQUEST$;
  }

  /**
   * Function To generate title from GenAI
   */
  public generatePageTitle(message: Message): Observable<string> {
    // this.chatbotStore.setLoadingState(true);
    const URL = `${this.baseUri}${this.chatbotTitleGenerateUri}`;
    const OPTIONS = this.getOptions();

    let request = {
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      query: message?.content?.query
    }

    const title$ = this.http.post<ChatbotTitleResponse>(URL, request, OPTIONS).pipe(
      switchMap((response) => {
        if (!response) {
          return throwError(response);
        }
        // this.chatbotStore.setConversationFromHistory(response.title);

        return of(response.title);
      }),
      finalize(() => {
        // this.chatbotStore.setLoadingState(false);
      }),
      catchError((error) => {
        // create operation mapping for http exception handling
        return this.ouxHandleError("fetchGenAIResponse", error)(error);
      })
    );

    return title$;
  }

  /**
   * Function To generate unique id
   */
  public generateUniqueId(): Observable<number> {
    const url = `${this.baseUri}${this.chatbotUniqueIdGenerateUri}`;
    const options = this.getOptions();

    let request$ = this.http.get<{ 'id': number }>(url, options)
      .pipe(
        map(response => {
          if (!response) {
            return null;
          }

          return response.id;
        }),
        catchError(error => {
          // create operation mapping for http exception handling 
          return this.ouxHandleError('fetchRefreshDate', error)(error);
        })
      );

    return request$;
  }

  /**
   * Function To initialize unique id queue
   */
  public initializeIdQueue() {
    const currentIds = this.chatbotStore.idQueue.getValue();
    if (currentIds.length < 2) {
      forkJoin([this.generateUniqueId(), this.generateUniqueId()]).subscribe(ids => {
        this.chatbotStore.idQueue.next(ids);
      });
    }
  }

  /**
   * Function To get a unique id
   */
  public popUniqueId(): number {
    const currentIds = this.chatbotStore.idQueue.getValue();
    const latestId = currentIds.shift();

    if (currentIds.length < 2) {
      this.generateUniqueId().subscribe(newId => {
        currentIds.push(newId);
        this.chatbotStore.idQueue.next(currentIds);
      });
    }

    return latestId;
  }

  /**
   * Methods To sanitize HTML
   */

  public sanitizeHtml(rawHtml): SafeHtml {
    const sanitizedHtml = DOMPurify.sanitize(rawHtml);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
  }

  public convertMarkdownToHtml(response: GenAIResponse): SafeHtml {
    if (!response || !response.output_text) {
      return response;
    }

    const adjustedMarkdown = response.output_text.replace(/\n\n/g, '\n<p>&nbsp;</p>\n');
    const rawHtml = this.converter.makeHtml(adjustedMarkdown);
    const cleanHtml = this.sanitizeHtml(rawHtml);

    return cleanHtml;
  }


  /**
   * Function To clean references object
   */
  private removeDuplicateFilenames(sources: Source[]): Source[] {
    const uniqueFilenames = new Set<string>();
    const result: Source[] = [];

    for (const source of sources) {
      let baseFilename = source.title;
      let lastIndex = baseFilename.lastIndexOf('.');

      if (lastIndex !== -1) {
        baseFilename = baseFilename.substring(0, lastIndex);
      }

      if (!uniqueFilenames.has(baseFilename) && baseFilename.length > 0) {
        uniqueFilenames.add(baseFilename);
        result.push(source);
      }
    }
    
    return result;
  }
}
