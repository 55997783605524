/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy }                 from '@angular/core';
/* RxJS Imports */
import { Subscription }       from 'rxjs';
/* Package Imports */
import { 
  OuxBrowserSupportPartialService,
  OuxLayoutService, 
  OuxLoggerService }           from '@cisco/oux-common';
import { LoaderService }       from '../core/services/loader.service';
import { InterfaceBase }       from './interface.base';
import { InstanceService }     from '../shared/services';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { LoadingType } from '../shared/models/constants/loading-type.enum';
import { ChatbotStore } from '../shared/stores/chatbot.store';


@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss'],
  host: {
    'class': 'app-interface'
  },
})
export class InterfaceComponent extends InterfaceBase implements OnInit, OnDestroy {

  public isLoading: boolean;
  public chatbotPageActive: boolean = false;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(router : Router,
              ouxLayoutSvc : OuxLayoutService,
              ouxLoggerSvc : OuxLoggerService,
              ouxBrowserSupportSvc : OuxBrowserSupportPartialService,
              instanceSvc : InstanceService,
              private chatbotStore: ChatbotStore,
              loaderSvc: LoaderService) {
    super(
      router,
      ouxLayoutSvc,
      ouxLoggerSvc,
      ouxBrowserSupportSvc,
      instanceSvc,
      loaderSvc
    );

    this.subscriptions.push(
      this.loaderSvc.loading$.get(LoadingType.Full).pipe(delay(0)).subscribe( (loading : boolean) => {
        this.isLoading = loading;
        this.ouxLoggerSvc.logDebug('Is loading...', this.isLoading);
    }));

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {

    this.chatbotStore.chatbotActive$.subscribe(isActive => {
      this.chatbotPageActive = isActive;
    });

    this.instanceSvc.instanceMountedAt('InterfaceComponent', 'app-interface');
    this.setLayoutStates();

    // check browser to ensure user is not using IE
    if (this.ouxBrowserSupportSvc.checkIfIE()) {
      this.router.navigate(['browser-not-supported']);
    }
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Convenience function. Responsible for envoking all state initialization code
   */
  private setLayoutStates() : void {
    this.checkSideNavState();
    this.checkFooterState();
  }

  /**
   * Adds --full-width class to interface template if showSideNav is false
   */
  private checkSideNavState() : void {
    this.subscriptions.push(
      this.ouxLayoutSvc.getSideNavState()
        .subscribe( (state : boolean) => {
          this.showSideNav = state;
        })
    );
  }

  /**
   * Adds --has-footer class to interface template if showFooter is true
   */
  private checkFooterState() : void {
    this.subscriptions.push(
      this.ouxLayoutSvc.getFooterState()
        .subscribe( (state : boolean) => {
          this.showFooter = state;
        })
    );
  }

}
