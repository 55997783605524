/* Angular Imports */
import { 
    Component, 
    OnInit }                                    from '@angular/core';
  /* RxJs Imports */
  import {
    Subscription }                              from 'rxjs';
  /* OneUX Imports */
  import {
    OuxModalService }                           from '@cisco/oux-common';
  import { VizbotModalComponent }               from '../vizbot-modal/vizbot-modal.component';

  
  @Component({
    selector: 'toolbar-vizbot',
    templateUrl: './toolbar-vizbot.component.html',
    styleUrls: ['./toolbar-vizbot.component.scss'],
    host: {
      'class': 'toolbar-vizbot'
    }
  })
  export class ToolbarVizbotComponent implements OnInit {
  
    ////////////////////////////////////////////////
    // Members
    ////////////////////////////////////////////////
  
    private subscriptions : Subscription[] = [];

  
    ////////////////////////////////////////////////
    // Dependency Injection
    ////////////////////////////////////////////////
  
    constructor(private ouxModalSvc: OuxModalService) {
    }
  
    ////////////////////////////////////////////////
    // Lifecycle Hooks
    ////////////////////////////////////////////////
  
    ngOnInit() : void {}
  
    public ngOnDestroy() : void {
      this.subscriptions.forEach(sub => sub.unsubscribe());
      this.subscriptions = [];
    }
  
    ////////////////////////////////////////////////
    // Public Methods
    ////////////////////////////////////////////////
  
  
    public openVizbotModal(event: any) : void {
      this.ouxModalSvc.openModal(VizbotModalComponent);
    }
  
  }