import { 
  Directive, 
  HostBinding, 
  Input, 
  ElementRef, 
  Renderer2,
  HostListener,
  AfterViewInit}                      from '@angular/core';

@Directive({
  selector: '[draggableBlock]',
})
export class DraggableDirective implements AfterViewInit {
  private isDragging = false;
  private initialX = 0;
  private initialY = 0;
  private currentX = 0;
  private currentY = 0;
  private xOffset = 0;
  private yOffset = 0;
  private handle: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.setStyle(this.el.nativeElement, 'position', 'absolute');
  }

  ngAfterViewInit() {
    this.handle = this.el.nativeElement.querySelector('.draggable-handle');
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (event.target === this.handle || this.handle.contains(event.target as Node)) {

      const element = this.el.nativeElement;
      const rect = element.getBoundingClientRect();

      this.initialX = event.clientX - this.xOffset;
      this.initialY = event.clientY - this.yOffset;
      this.isDragging = true;
      event.preventDefault();
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.isDragging = false;
    this.initialX = this.currentX;
    this.initialY = this.currentY;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      event.preventDefault();

      this.currentX = event.clientX - this.initialX;
      this.currentY = event.clientY - this.initialY;
      // Boundary checks
      const element = this.el.nativeElement;
      const rect = element.getBoundingClientRect();

      //Set boundaries for the block within viewport
      if (rect.left + this.currentX +window.innerWidth*0.6 < 0) this.currentX =-window.innerWidth*0.6 -rect.left;
      if (rect.top + this.currentY < 0) this.currentY = -rect.top;
      if (rect.right + this.currentX > window.innerWidth) this.currentX = window.innerWidth - rect.right;
      if (rect.bottom + this.currentY > window.innerHeight) this.currentY = window.innerHeight - rect.bottom;


      this.xOffset = this.currentX;
      this.yOffset = this.currentY;

      this.renderer.setStyle(this.el.nativeElement, 'transform', `translate3d(${this.currentX}px, ${this.currentY}px, 0)`);
    }
  }
}