import { Injectable } from '@angular/core';

import {
  OuxConfigService,
  OuxLoggerService,
  OuxThemeType,
  OuxToastOptions,
  OuxToastService
} from '@cisco/oux-common';

import {EMPTY, forkJoin, of, Subscription} from "rxjs";
import {catchError, finalize, switchMap, tap} from "rxjs/operators";
import {OrdersGoalToCashRequestModel} from "../../models/concrete/request/orders-goal-to-cash-request.model";
import {OrdersGoalToCashRequest} from "../../models/interface/request/orders-goal-to-cash-request";
import {OrderSearchSearchType} from "../../models/types/order-search-search-type.enum";
import {GoalToCashService} from "../goal-to-cash.service";
import {ExportType} from "../../models/constants/export-type.enum";
import {PaymentLinesService} from "../payment-lines.service";
import {OrderService} from "../order.service";
import {CreditMemoTransactionLinesAdjustmentsService} from "../credit-memo-transaction-lines-adjustments.service";
import { EmailService } from '../email.service';
import { VisibilityPageType } from '../../models/types/visibility-page-type.enum';
import { Filters } from '../../models/interface/partials/filters';
import { AmpIdentifierKey } from '../../models/types/amp-identifier-key.enum';
import { ExportDisplayType, OfflineExportType } from '../../models/types/offline-export-type.enum';


@Injectable({
  providedIn: 'root'
})
export class SubmitExportService {

  public fileName: string;
  private subscriptions: Subscription[] = [];
  private type: ExportType;
  constructor(private ouxToastSvc: OuxToastService,
              private ouxConfigSvc: OuxConfigService,
              private goalToCashSvc: GoalToCashService,
              private emailService: EmailService,
              private paymentLinesSvc: PaymentLinesService,
              private orderService: OrderService,
              private ouxLoggerSvc: OuxLoggerService,
              private adjustmentsSvc: CreditMemoTransactionLinesAdjustmentsService) {
  }

  /*
  * Exports from Payments VPT Screen
  * */
  public queueOfflineExport(request): void {
    this.subscriptions.push(
      this.paymentLinesSvc.queueOfflineExport(request, VisibilityPageType.Payments)
        .pipe(
          tap(() => {
            this.ouxToastSvc.addToast(new OuxToastOptions({
              header: 'Request submission successful',
              message: 'Your export is being processed. You should receive an email with an Export Link.',
              icon: 'icon-check',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Success
            }));
          }),
          catchError(error => {
            this.ouxToastSvc.addToast(new OuxToastOptions({
              header: 'Request submission failed',
              message: 'Your export request couldn\'t be processed. Please try again.',
              icon: 'icon-error-outline',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Error
            }));

            return EMPTY;
          }),

          switchMap(sequenceId => this.emailService.sendExportRequestEmail(sequenceId).pipe(
            catchError(error => {
              this.ouxLoggerSvc.logError(`SubmitExportService:queueOfflineExport() Failed to send email with sequenceId: -> ${sequenceId}`);
              return of(error);
            })
          )),
          finalize(() => {})
        )
        .subscribe()
    );

  }

  public queueMetricsOfflineExport(request, type: ExportType): void {
    this.subscriptions.push(
      this.goalToCashSvc.queueMetricsOfflineExport(request, type)
        .pipe(
          tap(() => {
            this.ouxToastSvc.addToast(new OuxToastOptions({
              header: 'Request submission successful',
              message: 'Your export is being processed. You should receive an email with an Export Link.',
              icon: 'icon-check',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Success
            }));
          }),
          catchError(error => {
            this.ouxToastSvc.addToast(new OuxToastOptions({
              header: 'Request submission failed',
              message: 'Your export request couldn\'t be processed. Please try again.',
              icon: 'icon-error-outline',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Error
            }));

            return EMPTY;
          }),

          switchMap(sequenceId => this.emailService.sendExportRequestEmail(sequenceId).pipe(
            catchError(error => {
              this.ouxLoggerSvc.logError(`SubmitExportService:queueMetricsOfflineExport() Failed to send email with sequenceId: -> ${sequenceId}`);
              return of(error);
            })
          )),
          finalize(() => {})
        )
        .subscribe()
    );
  }

  public queueOfflineAllGTCExport(request, exportLinesRequest): void {
    //Create a batch number for export request
    let batchId = this.generateBatchId();

    forkJoin({
      directTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(request as OrdersGoalToCashRequest), OrderSearchSearchType.Direct, Number(batchId), true))),
      posTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(request as OrdersGoalToCashRequest), OrderSearchSearchType.POS, Number(batchId), true))),
      xaasTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(request as OrdersGoalToCashRequest), OrderSearchSearchType.XAAS, Number(batchId), true))),
      bookingAdjTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(request as OrdersGoalToCashRequest), OrderSearchSearchType.BookingAdjustment, Number(batchId), true))),
      memoTrx: this.goalToCashSvc.queueOfflineAdjExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(exportLinesRequest as OrdersGoalToCashRequest), OrderSearchSearchType.CreditDebitMemo, Number(batchId), true))),
      manRevTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(request as OrdersGoalToCashRequest), OrderSearchSearchType.ManualRevenue, Number(batchId), true))),
      revAdjTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(request as OrdersGoalToCashRequest), OrderSearchSearchType.RevenueAdjustment, Number(batchId), true)))
    }).subscribe(({directTrx, posTrx,xaasTrx,bookingAdjTrx,memoTrx,manRevTrx,revAdjTrx})=>{

        this.ouxToastSvc.addToast(new OuxToastOptions({
          icon: 'icon-export',
          header: 'Request submission successful',
          message: 'Your export is being processed. You should receive an email with an Export Link.',
          autoDismiss: true,
          dismissTimeout: 5,
          scaleOnHover: false,
          theme: OuxThemeType.Success
        }));
        // call sendEmail service
    this.emailService.sendExportRequestEmail(Number(batchId)).subscribe(
      success => {
        // handle success
      },
      error => {
        // handle error
      }
    );
      },
      error => {
        // this.toastService.dismissToast(this.exportingToast);
        this.ouxToastSvc.addToast(new OuxToastOptions({
          icon: 'icon-export',
          header: 'Export',
          message: 'Failed to export orders.',
          autoDismiss: true,
          dismissTimeout: 5,
          scaleOnHover: false,
          theme: OuxThemeType.Error
        }));
      });
  }
  public setType(value: OrdersGoalToCashRequest, type: OrderSearchSearchType, batchId: number, batch: boolean): OrdersGoalToCashRequest{

    //return the array with field 'name' set to value 'type'
    const newArr = value?.filters?.map(object => {
      if(object.name==="type")
        return {...object, value: type};
      return object;
    });

    //Set the new filters into the request
    let requestedType = OrdersGoalToCashRequestModel.setFiltersForRequest(value, newArr, batchId, batch, type)

    return requestedType;

  }
  getRTMType(flag: string){
    if(flag==='E')
      return OrderSearchSearchType.Direct
    else if(flag==='P')
     return OrderSearchSearchType.POS
    else if(flag==='X')
      return OrderSearchSearchType.XAAS
    else if(flag=='revAdj')
      return(OrderSearchSearchType.RevenueAdjustment)
    else if(flag==='ManualAdjRev')
      return(OrderSearchSearchType.ManualRevenue)
    else return OrderSearchSearchType.Direct
  }

  getOrderType(filterData){
    const type = filterData?.find(object => object.name === "type")?.value;
    return type ? type : "All";
  }

  public getExportType(metricFlag: string, ampIdentifier: string, myFlag: string) {
    if(myFlag=== 'Y' || ampIdentifier==='MY')
    {
      return ExportDisplayType.MY;
    }
    if ((metricFlag === 'Y' && ampIdentifier === AmpIdentifierKey.CORE) || (metricFlag === 'Y' && ampIdentifier === 'CORE PE')) {
      return ExportDisplayType.Metric;
    }
    else if (metricFlag === 'Y' && ampIdentifier === AmpIdentifierKey.AMPLIFIER) {
      return ExportDisplayType.Amplifier;
    }
    else {
      return ExportDisplayType.Regular;
    }
  }

  public getType(filters: Filters[]): string | undefined {
    if (filters && filters.length > 0) {
      const filter = filters.find((f) => f.name === 'type');
      if (filter) {
        return filter.value;
      }
    }
    return undefined;
  }

  public queueOfflineGTCExport(request: OrdersGoalToCashRequest): void {

    this.subscriptions.push(
      this.goalToCashSvc.queueOfflineExport(request)
        .pipe(
          tap(() => {
            this.ouxToastSvc.addToast(new OuxToastOptions({
              header: 'Request submission successful',
              message: 'Your export is being processed. You should receive an email with an Export Link.',
              icon: 'icon-check',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Success
            }));
          }),
          catchError(error => {
            this.ouxToastSvc.addToast(new OuxToastOptions({
              header: 'Request submission failed',
              message: 'Your export request couldn\'t be processed. Please try again.',
              icon: 'icon-error-outline',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Error
            }));

            return EMPTY;
          }),
          switchMap(sequenceId => this.emailService.sendExportRequestEmail(sequenceId).pipe(
            catchError(error => {
              this.ouxLoggerSvc.logError(`SubmitExportService:queueOfflineGTCExport() Failed to send email with sequenceId: -> ${sequenceId}`);
              return of(error);
            })
          )),
          finalize(() => {})
        )

        .subscribe()
    );

  }

  public queueDirectPOSExport(request): void {
    this.subscriptions.push(
      this.orderService.queueExport(request)
        .pipe(
          tap(() => {
            this.ouxToastSvc.addToast(new OuxToastOptions({
              header: 'Request submission successful',
              message: 'Your export is being processed. You should receive an email with an Export Link.',
              icon: 'icon-check',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Success
            }));
          }),
          catchError(error => {
            this.ouxToastSvc.addToast(new OuxToastOptions({
              header: 'Request submission failed',
              message: 'Your export request couldn\'t be processed. Please try again.',
              icon: 'icon-error-outline',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Error
            }));

            return EMPTY;
          }),

          switchMap(sequenceId => this.emailService.sendExportRequestEmail(sequenceId).pipe(
            catchError(error => {
              this.ouxLoggerSvc.logError(`SubmitExportService:queueDirectPOSExport() Failed to send email with sequenceId: -> ${sequenceId}`);
              return of(error);
            })
          )),
          finalize(() => {})
        )
        .subscribe()
    );
  }

  public queueCmDmExport(request): void {
    
     //Add OrderSearchType to request
    //  const updatedRequest:any = {
    //   ...request,
    //   RTMType:OrderSearchSearchType.CreditDebitMemo,
    // };

    this.subscriptions.push(
      this.adjustmentsSvc.queueExport(request)
        .pipe(
          tap(() => {
            this.ouxToastSvc.addToast(new OuxToastOptions({
              header: 'Request submission successful',
              message: 'Your export is being processed. You should receive an email with an Export Link.',
              icon: 'icon-check',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Success
            }));
          }),
          catchError(error => {
            this.ouxToastSvc.addToast(new OuxToastOptions({
              header: 'Request submission failed',
              message: 'Your export request couldn\'t be processed. Please try again.',
              icon: 'icon-error-outline',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Error
            }));

            return EMPTY;
          }),

          switchMap(sequenceId => this.emailService.sendExportRequestEmail(sequenceId).pipe(
            catchError(error => {
              this.ouxLoggerSvc.logError(`SubmitExportService:queueCmDmExport() Failed to send email with sequenceId: -> ${sequenceId}`);
              return of(error);
            })
          )),
          finalize(() => {})
        )
        .subscribe()
    );
  }
  private generateBatchId(): string {
    //Ensure 9 digits long
    let randomPart = Math.random().toString().slice(2, 11);
      while (randomPart.length < 9) {
          randomPart += Math.floor(Math.random() * 10);
      }
      return randomPart.toString().slice(0, 9);
  }

}
