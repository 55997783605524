import { PageTitle } from "../../interface/partials/page-titles";

export class PageTitleModel implements PageTitle {
  page_header: string;
  pageId: number;
  interaction_id: number;
  creationDate: Date;

  constructor(args : Partial<PageTitle>) {
    Object.assign(this, args);
  } 

}