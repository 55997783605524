/* Angular Imports */
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { 
  Subscription,
  BehaviorSubject } from 'rxjs';
import { 
  tap,
  finalize } from 'rxjs/operators';
/* OneUX Imports */
import { OuxModalService } from '@cisco/oux-common';  
import { VizbotNewResponseService } from './vizbot-new-response.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import showdown from 'showdown';

@Component({
  selector: 'vizbot-new-modal',
  templateUrl: './vizbot-new-modal.component.html',
  styleUrls: ['./vizbot-new-modal.component.scss'],
  host: {
    'class': 'vizbot-new-modal'
  }
})
export class VizbotNewModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public searchValue: string;

  public isSearching: boolean = false;
  public loading$ = new BehaviorSubject<boolean>(false);
  public queryStorage = [];
  public responseStorage = [];

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxModalSvc: OuxModalService,
              private GenAIResponseService: VizbotNewResponseService,
              private sanitizer: DomSanitizer) {
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  public ngOnInit(): void { }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public search(): void {
    this.isSearching = true;

    this.queryStorage.push(this.searchValue);

    this.loading$.next(true);

    this.subscriptions.push(this.GenAIResponseService.fetchGenAIResponse(this.searchValue)
    .pipe(
      tap(response => {
        const converter = new showdown.Converter({ simpleLineBreaks: true });
        let adjustedMarkdown = '';
        if (response?.route_type === 'Structured') {
          for (let i = 0; i < response.result.length; i++) {
            adjustedMarkdown += JSON.stringify(response.result[i]) + '\n';          
          }
        } else {
          adjustedMarkdown = response?.output_text.replace(/\n\n/g, '\n<br/>');
        }
       
        const rawHtml = converter.makeHtml(adjustedMarkdown);
        let cleanHtml = this.sanitizeHtml(rawHtml);
        let responseObj = {
          ...response,
          html: cleanHtml
        }
        this.responseStorage.push(responseObj);
      }),
      finalize(() => {
        this.loading$.next(false);
        this.isSearching = false;
        this.searchValue = "";
      })
    )
    .subscribe());
  }

  public onSearchChange(): void {

  }

  public closeModal(): void {
    this.ouxModalSvc.closeModal();
  }

  public sanitizeHtml(rawHtml): SafeHtml {
    const sanitizedHtml = DOMPurify.sanitize(rawHtml);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
  }
}