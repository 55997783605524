import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  OuxAuthenticationService,
  OuxConfigService,
  OuxExceptionsHandleError,
  OuxExceptionsHandlerService,
} from "@cisco/oux-common";

import { UserDetailsStore } from "src/app/shared/stores/user-details.store";
import { MetadataStore } from "src/app/shared/stores/metadata.store";
import { GenAIResponse } from "src/app/shared/models/interface/partials/gen-ai-response";

@Injectable({ providedIn: "root" })
export class GenAIResponseService {
  private baseUri: string;
  private GenAIResponseUri: string;

  /**
   * Create service mapping for http exception handling
   */
  private ouxHandleError: OuxExceptionsHandleError =
    this.ouxExceptionsSvc.createHandleError("OrderService");

  constructor(
    private http: HttpClient,
    private ouxAuthSvc: OuxAuthenticationService,
    private ouxConfigSvc: OuxConfigService,
    private ouxExceptionsSvc: OuxExceptionsHandlerService,
    private userDetailsStore: UserDetailsStore,
    private metadataStore: MetadataStore,
  ) {
    let apiUri = this.ouxConfigSvc.getAppConfigValue("apiUri");

    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue("gatewayUri")}${this.ouxConfigSvc.getAppConfigValue("organizationUri")}${this.ouxConfigSvc.getAppConfigValue("apiVersion")}`;
    this.GenAIResponseUri = apiUri.chatbotResponse;
  }

  /**
   * Stages our Http Request Headers
   */
  private getOptions(): { headers: HttpHeaders } {
    const OPTIONS: { headers: HttpHeaders } = {
      headers: new HttpHeaders()
        .set("Authorization", this.ouxAuthSvc.getAuthToken())
        .append("Content-Type", "application/json"),
    };

    return OPTIONS;
  }

  public escapeQueryString(query: string): string {
    return query.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
  }

  public fetchGenAIResponse(query: any): Observable<GenAIResponse> {
    const URL = `${this.baseUri}${this.GenAIResponseUri}`;
    const OPTIONS = this.getOptions();

    let request = {
        employeeId: this.metadataStore.getMetadataEmployeeId(),
        loginId: this.userDetailsStore.getUserId(),
        userId: this.userDetailsStore.getImpersonationUserId(),
        query: this.escapeQueryString(query)
    }

    const REQUEST$ = this.http.post<GenAIResponse>(URL, request, OPTIONS).pipe(
      switchMap((response) => {
        if (!response) {
          return throwError(response);
        }

        return of(response);
      }),
      catchError((error) => {
        // create operation mapping for http exception handling
        return this.ouxHandleError("fetchGenAIResponse", error)(error);
      })
    );

    return REQUEST$;
  }
}
