import { SafeHtml } from "@angular/platform-browser";
import { Source, GenAIResponse } from "../../interface/partials/gen-ai-response";
import { Message } from "../../interface/partials/chatbot-message";

export class ChatbotMessageModel implements Message {
  public pageId: number;
  public interactionId: number;
  public updatedDate: Date;
  public content: GenAIResponse;
  public html?: SafeHtml;
  public thumbsUp?: boolean;
  public thumbsDown?: boolean;
  public copiedStatus: boolean = false;
  public feedback_rating?: number;

  constructor(args: Partial<ChatbotMessageModel>) {
    Object.assign(this, args);
  }
}