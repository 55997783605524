<div class="toolbar">
  <toolbar-vizbot></toolbar-vizbot>
  <toolbar-new-vizbot></toolbar-new-vizbot>
  <toolbar-help></toolbar-help>
  <toolbar-open-case></toolbar-open-case>
  
  <!-- not part of day 1 scope - will be part of future scope - do not remove - 9/28/21 -->
  <!-- <toolbar-notifications></toolbar-notifications> -->
  
  <toolbar-dashboards></toolbar-dashboards>
  <toolbar-theme></toolbar-theme>
  <toolbar-user></toolbar-user>
</div>