/* Angular Import */
import { Injectable } from '@angular/core';
/* Feature Imports */
import {
  OuxStore,
  OuxLoggerService
} from '@cisco/oux-common';
import { Conversation } from '../models/interface/partials/chatbot-conversation';
import { Source, GenAIResponse } from '../models/interface/partials/gen-ai-response';
import { Message } from '../models/interface/partials/chatbot-message';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { PageTitle } from '../models/interface/partials/page-titles';
import { ConversationHistory } from '../models/interface/partials/conversation-history';
import { ChatbotMessageModel } from '../models/concrete/partials/chatbot-message.model';
import { GenAIResponseModel } from '../models/concrete/partials/gen-ai-response.model';
import { ChatbotService } from '../services/chatbot.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import showdown from 'showdown';

/**
 * Creates our ChatBot State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class ChatbotStore {


  /**
   * Stage action stream to enable multicast strategy
   */
  private currentConversationSubject: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  private currentPageIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private currentMessageInteractionIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private pageTitlesSubject: BehaviorSubject<PageTitle[]> = new BehaviorSubject<PageTitle[]>([]);
  private chatbotActiveSubject = new BehaviorSubject<boolean>(false);
  public idQueue = new BehaviorSubject<number[]>([]);

  public isConversationActive$ = new BehaviorSubject<boolean>(true);
  public loading$ = new BehaviorSubject<boolean>(false);
  public loadingHistory$ = new BehaviorSubject<boolean>(true);


  /**
   * Stand up our transactions action stream (will retain the next emitted value)
   * This allows for us to react to changes made to the model
   */
  private converter = new showdown.Converter({ simpleLineBreaks: true });
  public currentPageId$ = this.currentPageIdSubject.asObservable();
  public currentMessageInteractionId$ = this.currentMessageInteractionIdSubject.asObservable();
  public chatbotActive$ = this.chatbotActiveSubject.asObservable();
  public currentConversation$: Observable<Message[]> = this.currentConversationSubject.asObservable();
  public pageTitles$: Observable<PageTitle[]> = this.pageTitlesSubject.asObservable();



  constructor(
    private sanitizer: DomSanitizer,
    private router: Router) {


    this.currentConversation$.pipe(
      map(messages => (messages ?? []).length)
    ).subscribe(length => {
      if (length && length > 0) {
        this.setConversationActive(true);
      } else {
        this.setConversationActive(false);
      }
    });

    //Set chatbotActiveSubject based on whether browser is on chatbot page
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const isActive = event.url === '/chatbot';
      this.chatbotActiveSubject.next(isActive);
    });
  }



  // Add a message to the current conversation
  addMessage(message: Message): void {
    if (this.currentConversationSubject.value === null) {
      this.currentConversationSubject.next([]);
    }
    const updatedConversation = [...this.currentConversationSubject.value, message];
    this.currentConversationSubject.next(updatedConversation);

  }

  // update a message with VizBots response to the current conversation
  updateMessage(message: Partial<Message>): void {
    if (this.currentConversationSubject.value === null) {
      this.currentConversationSubject.next([]);
    }
    const currentConversation = this.currentConversationSubject.getValue();

    const updatedMessages = currentConversation.map(record =>
      record.interactionId === message.interactionId
        ? { ...record, ...message }  // Merge new message properties into the existing message
        : record
    );

    this.currentConversationSubject.next(updatedMessages);

  }


  // Set the page title subject with values from database
  setPageTitles(pageTitles: PageTitle[]) {

    if (this.pageTitlesSubject.value === null) {
      this.pageTitlesSubject.next([]);
    }
    if (pageTitles && pageTitles.length != 0) {

      this.pageTitlesSubject.next(pageTitles);
    }
  }

   // Update the values of a page title object 
  updatePageTitles(pageTitle: PageTitle) {
    if (!pageTitle) {
      return;
    } else if (pageTitle.page_header.length === 0) {
      pageTitle.page_header = "Generic Request";
    }

    if (this.pageTitlesSubject.value === null) {
      this.pageTitlesSubject.next([]);
    }

    const currentTitles = this.pageTitlesSubject.value;
    const existingIndex = currentTitles.findIndex(title => title.pageId === pageTitle.pageId);

    if (existingIndex !== -1) {
      currentTitles[existingIndex] = pageTitle;
    } else {
      currentTitles.push(pageTitle);
    }

    this.pageTitlesSubject.next([...currentTitles]);
  }

  // Update the date of the page title to current date when a new message is added
  refreshPageTitles(pageId: number) {

    if (this.pageTitlesSubject.value === null) {
      this.pageTitlesSubject.next([]);
    }

    const currentTitles = this.pageTitlesSubject.value;
    const existingIndex = currentTitles.findIndex(title => title.pageId === pageId);

    if (existingIndex !== -1) {
      currentTitles[existingIndex].creationDate = new Date();
    }

    this.pageTitlesSubject.next([...currentTitles]);
  }


  deleteConversation(pageId: number) {
    const currentTitles = this.pageTitlesSubject.value;
    const updatedTitles = currentTitles.filter(title => title.pageId !== pageId);
    this.pageTitlesSubject.next([...updatedTitles]);
  }

   // Set the conversation subject with values from database
  setConversationFromHistory(pageId: number, conversations: ConversationHistory[]) {
    if (!conversations) {
    }
    this.clearConversationData();
    for (let i = conversations.length - 1; i >= 0; i--) {
      const conversation = conversations[i];
      let message = new ChatbotMessageModel({
        pageId: pageId,
        interactionId: conversation.interaction_id,
        html: conversation.answer ? this.convertMarkdownToHtml(new GenAIResponseModel({ output_text: conversation.answer })) : null,
        thumbsUp: conversation.feedback_rating > 0,
        thumbsDown: conversation.feedback_rating < 0,
        feedback_rating: conversation.feedback_rating,
        content: new GenAIResponseModel({
          query: conversation.question,
          output_text: conversation.answer,
          references: this.getSources(conversation.reference),
          doc_meta_info: null,
          is_answer_unknown: conversation.is_answer_unknown == "Y" ? true : false,
          intermediate_steps: null,
          errorCode: null,
          status: null,
          errorMessage: null,
        })
      });
      this.addMessage(message);
    };

  }



  public generateId(): number {
    return Math.floor(Math.random() * 9000000000) + 1000000000;
  }

   /**
   * Get and Set Methods
   * ============================================================
   */

  public setConversationActive(value: boolean) {
    this.isConversationActive$.next(value);
  }

  public setPageId(value: number) {
    this.currentPageIdSubject.next(value);
  }

  public setMessageInteractionId(value: number) {
    this.currentMessageInteractionIdSubject.next(value);
  }

  public setLoadingState(value: boolean) {
    this.loading$.next(value);
  }

  public setLoadingHistoryState(value: boolean) {
    this.loadingHistory$.next(value);
  }

  public getCurrentPageId(): number {
    return this.currentPageIdSubject.value;
  }

  public getIsConversationActive(): Observable<boolean> {
    return this.isConversationActive$.asObservable();
  }

  public getSources(reference: string): Source[] {
    return reference ? JSON.parse(reference) : [];
  }

  /**
   * Clear Chat Bot Data Collection
   * ============================================================
   */
  public clearConversationData(): void {
    this.currentConversationSubject.next(null);
  }



  /**
 * HTML Sanitization
 * ============================================================
 */
  private sanitizeHtml(rawHtml): SafeHtml {
    const sanitizedHtml = DOMPurify.sanitize(rawHtml);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
  }

  private convertMarkdownToHtml(response: GenAIResponse): SafeHtml {
    if (!response || !response.output_text) {
      return response;
    }

    const adjustedMarkdown = response.output_text.replace(/\n\n/g, '\n<p>&nbsp;</p>\n');
    const rawHtml = this.converter.makeHtml(adjustedMarkdown);
    const cleanHtml = this.sanitizeHtml(rawHtml);

    return cleanHtml;
  }

}