/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef }               from '@angular/core';
/* RxJS Imports */
import { EMPTY, Observable, of, Subscription }            from 'rxjs';
/* OneUX Imports */
import {
  OuxLayoutService,
  OuxGlobalNavOptions, 
  OuxGlobalNavType, 
  OuxGlobalNavThemeOverrides, 
  OuxGlobalNavItems,
  OuxLoggerService}     from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }         from '../../shared/services/instance.service';
import { QuotaDetailsStore } from 'src/app/shared/stores/quota-details.store';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { MetadataStore }  from 'src/app/shared/stores/metadata.store';
import { Router } from '@angular/router';
import { ChatbotStore } from 'src/app/shared/stores/chatbot.store';


/**
 * Located inside <app-body> under <app-hero>
 */
@Component({
  selector: 'app-shelf',
  templateUrl: './shelf.component.html',
  styleUrls: ['./shelf.component.scss'],
  host: {
    'class': 'app-shelf'
  }
})
export class ShelfComponent implements OnInit, OnDestroy {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  /**
   * Manage subscription - remember to unsubscribe when component is destroyed
   */
  private subscriptions : Subscription[] = [];
  private isMetricPilotUser;
  public isChatbotPageActive: boolean;
  
  /**
   * Stand up native element ref
   */
  private shelf : Node | any;

  /**
   * Is shelf present
   */
  public showShelf : boolean = null;

  /**
   * Set Global Nav Options
   */
  public setNavOptions : OuxGlobalNavOptions = new OuxGlobalNavOptions({
    type: OuxGlobalNavType.Underlined,
    navItems: [],
    show: true
  });

  public themeOverrides: OuxGlobalNavThemeOverrides = {
    color: '#FFFFFF',
    colorActive: '#FFFFFF'
  };

  private showMetricDetailsNavItem$ = this.quotaDetailsStore.isIACV$
    .pipe(
      switchMap((canShow: boolean) => {
        this.setGlobalNavItems(canShow);
        
        return of(canShow)
      }) 
    );

  /**
   * Hide Metric Order Search Tab if metricPilotUser value is 'N' in metadata response object 
   */
   public isMetricPilotUser$: Observable<boolean> = this.metadataStore.checkIfMetricPilotUser$
   .pipe(
     // takes first emitted value and completes - this is so we capture the initial logged in user's value once and leverage it the remainder of the applications lifecycle
     take(1),
     tap((changeUser: boolean) => {
      this.isMetricPilotUser = changeUser;
      this.setGlobalNavItems(null);
       this.ouxLoggerSvc.logDebug('HeaderComponent - isMetricPilotUser$:', changeUser);
     }),
     catchError((error: Error) => {
       this.ouxLoggerSvc.logDebug('HeaderComponent - isMetricPilotUser$:', error);
       return EMPTY;
     })
   );

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private vcRef : ViewContainerRef,
    private router: Router,
              private layoutSvc : OuxLayoutService,
              private instanceSvc : InstanceService,
              private quotaDetailsStore : QuotaDetailsStore,
              private ouxLoggerSvc : OuxLoggerService,
              private chatbotStore: ChatbotStore,
              private metadataStore: MetadataStore) {

    this.shelf = this.vcRef.element.nativeElement;

    this.subscriptions.push(this.showMetricDetailsNavItem$.subscribe());
    this.subscriptions.push(this.isMetricPilotUser$.subscribe());
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {

    this.chatbotStore.chatbotActive$.subscribe(isActive => {
      this.isChatbotPageActive = isActive;
    });

    this.instanceSvc.instanceMountedAt('ShelfComponent', 'app-shelf');
    this.setShelfState();
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Hides our Shelf Container located inside the Interface Template
   */
  private setShelfState() : void {
    this.subscriptions.push(
      this.layoutSvc.getShelfState()
        .subscribe(
          (state : boolean) => {
            this.showShelf = state;
        })
    );
  }

  exitPage(){
    this.router.navigate(['/goal-attainment'], { queryParams: null });
  }



  private setGlobalNavItems(flag: boolean) : void {
    // let metricPilotUserStatus = this.metadataStore.getMetadataEmployeeInfo().metricPilotUser;
    // let isMetricPilotUser = (metricPilotUserStatus === 'Y' ? true : false);

    switch (this.isMetricPilotUser) {
      case true:
        //this.setNavOptions.navItems = this.showGlobalNavWithMetricDetailsNavItem(); // Will be enabled as part of March Release
        
        this.setNavOptions.navItems = this.showGlobalNavWithMetricOrderView();
        break;
      default:
        this.setNavOptions.navItems = this.showDefaultGlobalNav();
        break;
    }
  }

  private showGlobalNavWithMetricDetailsNavItem() : OuxGlobalNavItems[] {
    return [
      new OuxGlobalNavItems({
        "caption": "Goal Attainment",
        "link": "/goal-attainment",
        "icon": "icon-analysis"
      }),
      new OuxGlobalNavItems({
        "caption": "Order Search",
        "link": "/order-search",
        "icon": "icon-search"
      }),
      new OuxGlobalNavItems({
        "caption": "Payments",
        "link": "/payments",
        "icon": "icon-calendar"
      }),
      new OuxGlobalNavItems({
        "caption": "Goal to Cash Summary",
        "link": "/goal-to-cash",
        "icon": "icon-watchlist"
      }),
      new OuxGlobalNavItems({
        "caption": "Metric Details",
        "link": "/metric-details",
        "icon": "icon-clipboard"
      }),
      new OuxGlobalNavItems({
        "caption": "Export Dashboard",
        "link": "/export-dashboard",
        "icon": "icon-download"
      })
    ]
  }

  private showGlobalNavWithMetricOrderView() : OuxGlobalNavItems[] {
    return [
      new OuxGlobalNavItems({
        "caption": "Goal Attainment",
        "link": "/goal-attainment",
        "icon": "icon-analysis"
      }),
      new OuxGlobalNavItems({
        "caption": "Order Search",
        "link": "/order-search",
        "icon": "icon-search"
      }),
      new OuxGlobalNavItems({
        "caption": "Payments",
        "link": "/payments",
        "icon": "icon-calendar"
      }),
      new OuxGlobalNavItems({
        "caption": "Goal to Cash Summary",
        "link": "/goal-to-cash",
        "icon": "icon-watchlist"
      }),
      new OuxGlobalNavItems({
        "caption": "Metric Order View",
        "link": "/metric-order-view",
        "icon": "icon-search"
      }),
      new OuxGlobalNavItems({
        "caption": "Export Dashboard",
        "link": "/export-dashboard",
        "icon": "icon-download"
      })
    ]
  }

  private showDefaultGlobalNav() : OuxGlobalNavItems[] {
    return [
      new OuxGlobalNavItems({
        "caption": "Goal Attainment",
        "link": "/goal-attainment",
        "icon": "icon-analysis"
      }),
      new OuxGlobalNavItems({
        "caption": "Order Search",
        "link": "/order-search",
        "icon": "icon-search"
      }),
      new OuxGlobalNavItems({
        "caption": "Payments",
        "link": "/payments",
        "icon": "icon-calendar"
      }),
      new OuxGlobalNavItems({
        "caption": "Goal to Cash Summary",
        "link": "/goal-to-cash",
        "icon": "icon-watchlist"
      }),
      new OuxGlobalNavItems({
        "caption": "Export Dashboard",
        "link": "/export-dashboard",
        "icon": "icon-download"
      })
    ]
  }

}
