import { Suggestion } from '../../interface/partials/suggestion';
import { Suggestions } from '../../interface/partials/suggestions';


export class SuggestionsModel implements Suggestions {
  
  public suggestions: Suggestion[];
  public errorMessage: string;

  constructor(args : Partial<SuggestionsModel>) {
    Object.assign(this, args);
  }

}