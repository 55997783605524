import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpeechRecognitionService {
  private recognition: any;
  private stopTimeout: any;

  constructor() {
    const SpeechRecognition = window['SpeechRecognition'] || window['webkitSpeechRecognition'];
    this.recognition = new SpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    // this.recognition.lang = 'en-US';
  }

  public start(): Observable<{ transcript: string; isFinal: boolean }> {
    return new Observable(observer => {
      let finalTranscript = '';

      this.recognition.onresult = (event) => {
        const result = event.results[event.resultIndex];
        const transcript = result[0].transcript.trim();

        if (result.isFinal) {
          finalTranscript += transcript;
          observer.next({ transcript: finalTranscript, isFinal: true });
        } else {
          observer.next({ transcript, isFinal: false });
        }
      };

      this.recognition.onspeechend = () => {
        // Add a 0.5-second delay before stopping the recognition
        this.stopTimeout = setTimeout(() => {
          this.stop(); 
        }, 1000);
      };

      this.recognition.onend = () => {
        clearTimeout(this.stopTimeout);
        observer.complete(); 
      };

      this.recognition.start();
    });
  }

  public stop() {
    this.recognition.stop();
  }
}