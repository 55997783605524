<div class="modal-container">
<oux-modal-content-container>
  <oux-modal-content-header
    [setOptions]="{ icon: 'icon-group-chat', heading: 'Welcome to VizBot! (GPT 3.5)' }"></oux-modal-content-header>
  
  <oux-modal-content-article>
    <div class="summary">
      <p>VizBot is your new generative AI digital assistant to help you with your questions on Visibility, Sales Compensation, policy documents, all things related to recurring revenue metrics and much more.
      </p>
      <h1 class="summary-header">Data Collection & Use</h1>
      <ul>
        <li>VizBot's underlying models reflect biases and other undesirable content present in the training data or the examples provided in the prompt.</li>
        <li>VizBot is not suitable for open-ended, unconstrained content generation, which may produce inaccurate, offensive, or harmful text.
        </li>
        <li>VizBot does not have information about events after its training date (2024), lacks sufficient knowledge about some topics, and may produce factually inaccurate information.
        </li>
      </ul>
    </div>
       
    <oux-spinner [show]="loading$ | async" [size]="'large'" [hasOverlay]="true"></oux-spinner>

    <div *ngIf="queryStorage.length > 0" class="session-history">
      <div *ngFor="let query of queryStorage; let i = index">
        <div class="query-history">
          <span class="session-history-label">You</span>
          <div>{{ query }}</div>
        </div>
        <div class="response-history">
          <span class="session-history-label">VizBot</span>
          <div *ngIf="responseStorage[i]?.output_text !== '' || responseStorage[i]?.route_type === 'Structured'; else errorResponse">
            <div [innerHtml]="responseStorage[i]?.html"></div>
          </div>
          <ng-template #errorResponse>
            <div>Sorry, VizBot is unable to provide an answer to your query at this time.</div>
          </ng-template>
          
          <div *ngIf="responseStorage[i]?.references?.length > 0 && !responseStorage[i]?.is_answer_unknown" class="sources">
            <span class="sources-label">Sources:</span>
            <ul class="sources-list">
              <li *ngFor="let source of responseStorage[i]?.references">
                <a [href]="source?.url">{{ source?.title }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="search-container">
      <div class="search__control">
        <input type="text" name="search" [(ngModel)]="searchValue" [disabled]="isSearching" [placeholder]="'Ask VizBot anything...'" (ngModelChange)="onSearchChange()" (keyup.enter)="search()" />
        <i [ngStyle]="{ 'color': searchValue && !isSearching ? 'var(--primary)' : '' }" class="icon icon-send" (click)="search()"></i>
      </div>
    </div>

    <div class="footer">
      <oux-button [options]="{ label : 'Close', style : 'flat', dataSelector : 'vizbot-modal-oJVlEZeAp7' }"
        (trigger)="closeModal()">
      </oux-button>
    </div>
  </oux-modal-content-article>
</oux-modal-content-container>
</div>